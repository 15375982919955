import React from 'react'
import styled from 'styled-components'
import VideoJS from '@/components/VideoPlayer/VideoPlayer'
import { Title, Paragraph, SubTitle } from '@/components/TextComponents/textStyles'
import { Videos } from '@/types/contentTypes'

interface HorizontalScrollVideosProps {
  videos: Videos
  categoryName: string
  handlePlayerReady: () => void
}

export const HorizontalScrollVideos = (props) => {
  return (
    <SectionWrapper>
      <SubTitle level="2" primary margin="0 0 10px">{props.categoryName}</SubTitle>
      <HorizontalScrollWrapper>
        {!!props.videos && props.videos.map((video) => {
          const videoJsOptions = {
            preferFullWindow: true,
            autoplay: false,
            controls: true,
            fluid: true,
            sources: [{
              src: `https://stream.mux.com/${video.muxStreamingId}.m3u8`,
            }]
          }
          return (
            <VideoWrapper>
              <VideoJS options={videoJsOptions} onReady={props.handlePlayerReady} />
              <Title level="6" margin="21px 0 6px">{video.title}</Title>
              <Paragraph level="1">{video.duration}</Paragraph>
            </VideoWrapper>
          )}
        )}
      </HorizontalScrollWrapper>
    </SectionWrapper>
)}

const HorizontalScrollWrapper = styled.div`
  display: flex;
  overflow-x: auto;
`

const SectionWrapper = styled.section`
  width: 100%;
  padding: 20px 0;
`

const VideoWrapper = styled.div`
  margin: 10px 8px;
  min-width: 85%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`