import React from 'react'
import { graphql } from 'gatsby'
import GlobalStyle from '@/styles/GlobalStyles'
import VideoJS from '@/components/VideoPlayer/VideoPlayer'
import { Title } from '@/components/TextComponents/textStyles'
import { HorizontalScrollVideos } from '../components/HorizontalScrollVideos'
import { VerticalScrollVideos } from '../components/VerticalScrollVideos'
import { intervalToDuration } from 'date-fns'

const ExplainVideos = ({ data }) => {
  const _data = data.directus.clinic[0].video
  const videosHeadline = data.directus.TextGlobals.video_headline
  const playerRef = React.useRef(null)

  //convert/format util function
  const convertSecondsToMinutes = (seconds: number) => {
    const time = intervalToDuration({ start: 0, end: seconds * 1000 })
    const padZeros = (numberInput) => String(numberInput).padStart(2, '0')
    return `${padZeros(time.minutes)}:${padZeros(time.seconds)}`
  }

  // transform data to schema
  const videos = _data.map((video) => ({
    id: video.id ?? '',
    title: video.title ?? '',
    description: video.description ?? '',
    posterImage:
      video.poster_image ??
      `https://image.mux.com/${video.mux_id[0]?.mux_streaming_id}/thumbnail.png`,
    muxStreamingId: video.mux_id[0]?.mux_streaming_id ?? '',
    duration: convertSecondsToMinutes(video.mux_id[0]?.duration) ?? '00:00',
    categories:
      video.categories.map((category) => ({
        id: category.video_categories_id?.id ?? '',
        name: category.video_categories_id?.name ?? '',
        order: category.video_categories_id?.sort ?? '',
        isHorizontal:
          category.video_categories_id?.horizontal_direction ?? false,
      })) ?? '',
  }))

  const videosByCategory = {}
  const videoCategoryInfo = {}

  for (const video of videos) {
    if(!!video.categories && Array.isArray(video.categories)) {
      for (const category of video.categories) {
        if(!videosByCategory[category.id]) {
          videosByCategory[category.id] = []
        }
        if (!videoCategoryInfo[category.id]) {
          videoCategoryInfo[category.id] = {
            name: category.name,
            isHorizontal: category.isHorizontal,
            order: category.order
          }
        }
        videosByCategory[category.id].push(video)
      }
    }
  }

  //order category array by sort attribute
  const orderedCategories = Object.entries(videoCategoryInfo)
    .sort((category1: any, category2: any) => category1[1].order - category2[1].order)

  //create new video object from old one with category sorting
  const compareOrderOfVideoWithCategoryArray = (videoArray, categoryArray) => {
    let sortedVideosObj = {}
    for (const video of videoArray) {
      for (const category of categoryArray) {
        if (category[0] === video[0]) {
          sortedVideosObj[category[0]] = video[1]
        }
      }
    }
    return sortedVideosObj
  }

  const orderedVideos = compareOrderOfVideoWithCategoryArray(Object.entries(videosByCategory), orderedCategories)

  const handlePlayerReady = (player) => {
    playerRef.current = player

    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('dispose', () => {
      console.log('player will dispose')
    })
  }

  return (
    <main>
      <GlobalStyle />
      <Title as="h4" level="4" margin="0 0 10px">{videosHeadline}</Title>
      <div>
        {Object.entries(orderedVideos)
          .map((videos) => {
            const category = videoCategoryInfo[videos[0]] ?? ''
            const categoryDirectionIsHorizontal: boolean = category.isHorizontal ?? false

            return (
              categoryDirectionIsHorizontal ?
                <HorizontalScrollVideos
                  key={videos[0]}
                  videos={videos[1]}
                  handlePlayerReady={() => handlePlayerReady}
                  categoryName={category.name ?? ''}
                /> :
                <VerticalScrollVideos
                  key={videos[0]}
                  videos={videos[1]}
                  handlePlayerReady={() => handlePlayerReady}
                  categoryName={category.name ?? ''}
                />
            )}
          )}
      </div>
    </main>
  )
}

export const query = graphql`
  query Video($clinic: String) {
    directus {
      TextGlobals {
        video_headline
      }
      clinic(filter: {clinic_id: {_eq: $clinic}}) {
        clinic_id
        video {
          id
          title
          description
          mux_id {
            mux_streaming_id
            duration
          }
          categories {
            video_categories_id {
              id
              name
              horizontal_direction
              sort
            }
          }
        }
      }
    }
  }
`

export default ExplainVideos
