import React from 'react'
import styled from 'styled-components'
import VideoJS from '@/components/VideoPlayer/VideoPlayer'
import { Paragraph, Caption, DividerSubTitle } from '@/components/TextComponents/textStyles'
import { Videos } from '@/types/contentTypes'

interface VerticalScrollVideosProps {
  videos: Videos
  categoryName: string
  handlePlayerReady: () => void
}

export const VerticalScrollVideos = (props) => {
  return (
    <SectionWrapper>
      <DividerSubTitle>{props.categoryName}</DividerSubTitle>
      <VideoElementsWrapper>
        {!!props.videos &&
          props.videos.map((video) => {
            console.log(video, 'what is received?')
            const videoJsOptions = {
              preferFullWindow: true,
              autoplay: false,
              controls: true,
              responsive: true,
              poster: video.posterImage,
              sources: [
                {
                  src: `https://stream.mux.com/${video.muxStreamingId}.m3u8`,
                },
              ],
            }
            return (
              <VideoWrapperElement>
                <VideoWrapper>
                  <VideoJS options={videoJsOptions} onReady={props.handlePlayerReady} />
                </VideoWrapper>
                <ContentWrapper>
                  <Paragraph level="3" isSmall>
                    {video.title}
                  </Paragraph>
                  <Caption level="1">{video.duration} Minutes</Caption>
                </ContentWrapper>
              </VideoWrapperElement>
            )
          })}
      </VideoElementsWrapper>
    </SectionWrapper>
  )}

const VideoElementsWrapper = styled.div`
  padding: 20px 0;
`

const SectionWrapper = styled.section`
  padding: 20px 0;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const VideoWrapperElement = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-left: 0px;
`

const VideoWrapper = styled.div`
  padding-right: 20px;

  .vjs-hope {
    width: 148px !important;
    height: 108px !important;
    padding: -20px 0;
  }
`